import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2f660824 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _534a96d5 = () => interopDefault(import('../pages/BundleProducts.vue' /* webpackChunkName: "pages/BundleProducts" */))
const _10c2fd2c = () => interopDefault(import('../pages/BundleProductsCopy.vue' /* webpackChunkName: "pages/BundleProductsCopy" */))
const _633f8e90 = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _4233f044 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _945259ac = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _54862535 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _44bdc88f = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _2e4b9436 = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _a55524de = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _4624b08a = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _2ab443c3 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _1485db9e = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _5709f493 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _5766986d = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _492e06b1 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _48706fc2 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _7bbd6d1e = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _0ba70fbd = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _4f6a746c = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _793ff8bd = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _f132ee3c = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _3882119e = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _d03a620a = () => interopDefault(import('../pages/ProductHtml.vue' /* webpackChunkName: "pages/ProductHtml" */))
const _e679bdb6 = () => interopDefault(import('../pages/Sharewishlist.vue' /* webpackChunkName: "" */))
const _fbadb33c = () => interopDefault(import('../pages/UseApi.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de",
    component: _2f660824,
    name: "home___de"
  }, {
    path: "/dkk",
    component: _2f660824,
    name: "home___dkk"
  }, {
    path: "/en",
    component: _2f660824,
    name: "home___en"
  }, {
    path: "/de/BundleProducts",
    component: _534a96d5,
    name: "BundleProducts___de"
  }, {
    path: "/de/BundleProductsCopy",
    component: _10c2fd2c,
    name: "BundleProductsCopy___de"
  }, {
    path: "/de/cart",
    component: _633f8e90,
    name: "cart___de"
  }, {
    path: "/de/checkout",
    component: _4233f044,
    name: "checkout___de",
    children: [{
      path: "billing",
      component: _945259ac,
      name: "billing___de"
    }, {
      path: "payment",
      component: _54862535,
      name: "payment___de"
    }, {
      path: "shipping",
      component: _44bdc88f,
      name: "shipping___de"
    }, {
      path: "thank-you",
      component: _2e4b9436,
      name: "thank-you___de"
    }, {
      path: "user-account",
      component: _a55524de,
      name: "user-account___de"
    }]
  }, {
    path: "/de/Cms",
    component: _4624b08a,
    name: "Cms___de"
  }, {
    path: "/de/customer",
    component: _2ab443c3,
    meta: {"titleLabel":"My Account"},
    name: "customer___de",
    children: [{
      path: "addresses-details",
      component: _1485db9e,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___de"
    }, {
      path: "my-newsletter",
      component: _5709f493,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___de"
    }, {
      path: "my-profile",
      component: _5766986d,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___de"
    }, {
      path: "my-reviews",
      component: _492e06b1,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___de"
    }, {
      path: "my-wishlist",
      component: _48706fc2,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___de"
    }, {
      path: "order-history",
      component: _7bbd6d1e,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___de"
    }, {
      path: "addresses-details/create",
      component: _0ba70fbd,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___de"
    }, {
      path: "/de/reset-password",
      component: _4f6a746c,
      alias: "/customer/account/createPassword",
      name: "reset-password___de"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _793ff8bd,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___de"
    }, {
      path: "order-history/:orderId",
      component: _f132ee3c,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___de"
    }]
  }, {
    path: "/de/framed-prints",
    component: _534a96d5,
    name: "BundleProducts___de"
  }, {
    path: "/de/Home",
    component: _2f660824,
    name: "Home___de"
  }, {
    path: "/de/Page",
    component: _3882119e,
    name: "Page___de"
  }, {
    path: "/de/ProductHtml",
    component: _d03a620a,
    name: "ProductHtml___de"
  }, {
    path: "/de/sharewishlist",
    component: _e679bdb6,
    name: "Sharewishlist___de"
  }, {
    path: "/de/Sharewishlist",
    component: _e679bdb6,
    name: "Sharewishlist___de"
  }, {
    path: "/de/use-api",
    component: _fbadb33c,
    name: "UseApi___de"
  }, {
    path: "/de/UseApi",
    component: _fbadb33c,
    name: "UseApi___de"
  }, {
    path: "/dkk/BundleProducts",
    component: _534a96d5,
    name: "BundleProducts___dkk"
  }, {
    path: "/dkk/BundleProductsCopy",
    component: _10c2fd2c,
    name: "BundleProductsCopy___dkk"
  }, {
    path: "/dkk/cart",
    component: _633f8e90,
    name: "cart___dkk"
  }, {
    path: "/dkk/checkout",
    component: _4233f044,
    name: "checkout___dkk",
    children: [{
      path: "billing",
      component: _945259ac,
      name: "billing___dkk"
    }, {
      path: "payment",
      component: _54862535,
      name: "payment___dkk"
    }, {
      path: "shipping",
      component: _44bdc88f,
      name: "shipping___dkk"
    }, {
      path: "thank-you",
      component: _2e4b9436,
      name: "thank-you___dkk"
    }, {
      path: "user-account",
      component: _a55524de,
      name: "user-account___dkk"
    }]
  }, {
    path: "/dkk/Cms",
    component: _4624b08a,
    name: "Cms___dkk"
  }, {
    path: "/dkk/customer",
    component: _2ab443c3,
    meta: {"titleLabel":"My Account"},
    name: "customer___dkk",
    children: [{
      path: "addresses-details",
      component: _1485db9e,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___dkk"
    }, {
      path: "my-newsletter",
      component: _5709f493,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___dkk"
    }, {
      path: "my-profile",
      component: _5766986d,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___dkk"
    }, {
      path: "my-reviews",
      component: _492e06b1,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___dkk"
    }, {
      path: "my-wishlist",
      component: _48706fc2,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___dkk"
    }, {
      path: "order-history",
      component: _7bbd6d1e,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___dkk"
    }, {
      path: "addresses-details/create",
      component: _0ba70fbd,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___dkk"
    }, {
      path: "/dkk/reset-password",
      component: _4f6a746c,
      alias: "/customer/account/createPassword",
      name: "reset-password___dkk"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _793ff8bd,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___dkk"
    }, {
      path: "order-history/:orderId",
      component: _f132ee3c,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___dkk"
    }]
  }, {
    path: "/dkk/framed-prints",
    component: _534a96d5,
    name: "BundleProducts___dkk"
  }, {
    path: "/dkk/Home",
    component: _2f660824,
    name: "Home___dkk"
  }, {
    path: "/dkk/Page",
    component: _3882119e,
    name: "Page___dkk"
  }, {
    path: "/dkk/ProductHtml",
    component: _d03a620a,
    name: "ProductHtml___dkk"
  }, {
    path: "/dkk/sharewishlist",
    component: _e679bdb6,
    name: "Sharewishlist___dkk"
  }, {
    path: "/dkk/Sharewishlist",
    component: _e679bdb6,
    name: "Sharewishlist___dkk"
  }, {
    path: "/dkk/use-api",
    component: _fbadb33c,
    name: "UseApi___dkk"
  }, {
    path: "/dkk/UseApi",
    component: _fbadb33c,
    name: "UseApi___dkk"
  }, {
    path: "/en/BundleProducts",
    component: _534a96d5,
    name: "BundleProducts___en"
  }, {
    path: "/en/BundleProductsCopy",
    component: _10c2fd2c,
    name: "BundleProductsCopy___en"
  }, {
    path: "/en/cart",
    component: _633f8e90,
    name: "cart___en"
  }, {
    path: "/en/checkout",
    component: _4233f044,
    name: "checkout___en",
    children: [{
      path: "billing",
      component: _945259ac,
      name: "billing___en"
    }, {
      path: "payment",
      component: _54862535,
      name: "payment___en"
    }, {
      path: "shipping",
      component: _44bdc88f,
      name: "shipping___en"
    }, {
      path: "thank-you",
      component: _2e4b9436,
      name: "thank-you___en"
    }, {
      path: "user-account",
      component: _a55524de,
      name: "user-account___en"
    }]
  }, {
    path: "/en/Cms",
    component: _4624b08a,
    name: "Cms___en"
  }, {
    path: "/en/customer",
    component: _2ab443c3,
    meta: {"titleLabel":"My Account"},
    name: "customer___en",
    children: [{
      path: "addresses-details",
      component: _1485db9e,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___en"
    }, {
      path: "my-newsletter",
      component: _5709f493,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___en"
    }, {
      path: "my-profile",
      component: _5766986d,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___en"
    }, {
      path: "my-reviews",
      component: _492e06b1,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___en"
    }, {
      path: "my-wishlist",
      component: _48706fc2,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___en"
    }, {
      path: "order-history",
      component: _7bbd6d1e,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___en"
    }, {
      path: "addresses-details/create",
      component: _0ba70fbd,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___en"
    }, {
      path: "/en/reset-password",
      component: _4f6a746c,
      alias: "/customer/account/createPassword",
      name: "reset-password___en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _793ff8bd,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___en"
    }, {
      path: "order-history/:orderId",
      component: _f132ee3c,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___en"
    }]
  }, {
    path: "/en/framed-prints",
    component: _534a96d5,
    name: "BundleProducts___en"
  }, {
    path: "/en/Home",
    component: _2f660824,
    name: "Home___en"
  }, {
    path: "/en/Page",
    component: _3882119e,
    name: "Page___en"
  }, {
    path: "/en/ProductHtml",
    component: _d03a620a,
    name: "ProductHtml___en"
  }, {
    path: "/en/sharewishlist",
    component: _e679bdb6,
    name: "Sharewishlist___en"
  }, {
    path: "/en/Sharewishlist",
    component: _e679bdb6,
    name: "Sharewishlist___en"
  }, {
    path: "/en/use-api",
    component: _fbadb33c,
    name: "UseApi___en"
  }, {
    path: "/en/UseApi",
    component: _fbadb33c,
    name: "UseApi___en"
  }, {
    path: "/de/pages/framedprints",
    component: _10c2fd2c,
    name: "BundleProductsCopy___de"
  }, {
    path: "/dkk/pages/framedprints",
    component: _10c2fd2c,
    name: "BundleProductsCopy___dkk"
  }, {
    path: "/en/pages/framedprints",
    component: _10c2fd2c,
    name: "BundleProductsCopy___en"
  }, {
    path: "/de/:slug+",
    component: _3882119e,
    name: "page___de"
  }, {
    path: "/dkk/:slug+",
    component: _3882119e,
    name: "page___dkk"
  }, {
    path: "/en/:slug+",
    component: _3882119e,
    name: "page___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
